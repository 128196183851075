<template>
  <b-container class="mb-7">
    <b-row class="vh-100" align-v="center">
      <b-col cols="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3">
        <b-card class="card-login shadow rounded mx-auto">
          <b-card-body class="flex flex-col items-center">
            <div class="flex items-center">
              <img class="login-logo" src="@/assets/logo-banner-green.png" />
            </div>

            <Alert v-if="errorMessage" variant="red" icon="exclamation" show>
              <strong>{{ errorMessage }}</strong
              >. Having trouble? Email <a href="mailto:help@rafflebox.ca">help@rafflebox.ca</a>
            </Alert>

            <div class="text-center" v-if="success">
              <Alert variant="green" show>
                A password reset email has been sent to <strong>{{ email }}</strong>
              </Alert>
              <BaseButton size="lg" variant="success" @click.native="pushToLogin" class="m-auto mt-8">Login</BaseButton>
            </div>
            <b-form v-else class="forgot-password-form w-100" @submit.stop.prevent="sendResetPasswordEmail">
              <b-form-group
                label="Email Address"
                label-for="email"
                label-sr-only
                :invalid-feedback="veeErrors.first('email')"
              >
                <b-form-input
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  v-model="email"
                  v-validate="'required|email'"
                  :state="validateState('email')"
                  data-vv-as="email"
                />
              </b-form-group>

              <div>
                <BaseButton
                  size="lg"
                  variant="success"
                  :loading="busy"
                  @click.native="sendResetPasswordEmail"
                  class="m-auto"
                >
                  Reset Password
                </BaseButton>
              </div>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import UserServiceV2 from '@/lib/user-service-v2';
import Alert from '@/components/ui/Alert.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    Alert,
    BaseButton
  },
  data() {
    return {
      email: null,
      success: false,
      errorMessage: null,
      busy: false
    };
  },
  metaInfo: {
    title: 'Forgot Password'
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.email = null;
      this.success = false;
      this.error = null;

      this.veeErrors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    pushToLogin() {
      this.$router.push('/login');
    },
    async sendResetPasswordEmail() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.errorMessage = '';
      this.busy = true;

      this.sleep(2000);

      try {
        await UserServiceV2.sendResetPasswordEmail(this.email);

        this.success = true;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>

<style>
.forgot-password-form .form-group {
  position: relative;
  margin-bottom: 0;
}

.forgot-password-form .form-control {
  margin-bottom: 2rem;
  height: calc(2.5em + 0.75em + 2px);
  border-radius: 0.5rem;
}

.forgot-password-form input {
  margin-bottom: 2rem;
}

.forgot-password-form .invalid-feedback {
  position: absolute;
  bottom: -1.5rem;
  padding-left: 0.75rem;
}
</style>

<style scoped>
.container {
  margin: 0 auto;
}

.login-logo {
  max-width: 100%;
  max-height: 100%;
  max-width: 80%;
  padding-bottom: 1.5rem;
  margin: auto;
}

.app-title {
  padding-bottom: 2rem;
  text-align: center;
}

.credentials-form {
  text-align: left;
}

.card-body {
  padding: 1.25rem;
}

.alert-body {
  text-align: left;
}

.card {
  padding: 1.5rem;
}

.support-link {
  color: #004085;
  text-decoration: underline;
}

.support-link:focus,
.support-link:hover {
  text-decoration: none;
}
</style>
